import Homepage from '../views/Homepage';

export default [
  {
    name: 'homepage',
    path: '/',
    components: {
      mainContent: Homepage,
    }
  },
];
