import { createRouter, createWebHistory } from 'vue-router';
import homepageRoutes from './modules/homepage/routes/homepageRoutes';

const routes = [
  ...homepageRoutes
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkExactActiveClass: 'active',
  routes,
});

export default router;
