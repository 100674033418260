<template>
  <Layout />
</template>

<script>
import Layout from '@/components/layout/Layout';

export default {
  data() {
    return {
    };
  },
  components: {
    Layout
  },
};
</script>

<style lang="scss" scoped>
</style>
