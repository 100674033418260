<template>
  <div class="container">
    <div class="members">
      <h2 class="members__heading center" data-aos="fade-right" data-aos-duration="1000">
        Členská sekce
      </h2>

      <div class="members__text" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
        <p>
          V členské sekci je každý Drak jako doma. Zde se domlouváme na trénincích,
          zápasech, členských příspěvcích a zkrátka na všem, co je zrovna třeba vyřešit.
        </p>
      </div>

      <div class="members__action" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600">
        <a href="https://clen.fbc-dragons.cz" target="_blank" class="btn">
          Členská sekce
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import AOS from "aos";

export default {
  mounted() {
    AOS.init();
  }
};
</script>

<style lang="scss" scoped>
.members {
  padding: 150px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include for-size(md) {
    padding: 100px 0;
  }

  &__text {
    margin: 0 auto;
    max-width: 760px;
    p {
      text-align: center;
    }
  }
  &__action {
    margin-top: 50px;
  }
}
</style>
