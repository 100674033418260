<template>
  <div class="recruitment-wrapper" id="nabor">
    <div class="container">
      <div class="recruitment">
        <div class="recruitment__column">
          <h2 class="recruitment__heading" data-aos="fade-up" data-aos-duration="1000">
            Nábor
          </h2>
          <div class="recruitment__text" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="250">
            <p>
              Nábor probíhá po celý rok a to pro holky i kluky narozené mezi lety
              {{ to }}-{{ from }}. Pokud by jsi chtěl(a) zkusit florbal, neváhej se nám ozvat
              na e-mail <a href="mailto:nabor@fbc-dragons.cz">nabor@fbc-dragons.cz</a>.
            </p>
            <p>
              Nejdříve od tebe budeme potřebovat vědět tvůj ročník narození, pohlaví a
              případné zkušenosti s florbalem.
            </p>
          </div>
          <div class="recruitment__action" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500">
            <a href="mailto:nabor@fbc-dragons.cz" class="btn">Napiš nám</a>
          </div>
        </div>
        <div class="recruitment__column">
          <div class="recruitment__image" data-aos="fade-left" data-aos-duration="1000">
            <img :src="publicPath + 'images/recruitment.jpg'" alt="FBC Dragons nábor" title="FBC Dragons nábor" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AOS from "aos";

export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
      from: ((new Date).getFullYear() - 7),
      to: ((new Date).getFullYear() - 14),
    };
  },
  mounted() {
    AOS.init();
  },
};
</script>

<style lang="scss" scoped>
.recruitment-wrapper {
  background-image: url('~@/assets/panel1.jpg');
  background-size: cover;
  padding: 150px 0;

  @include for-size(md) {
    padding: 100px 0;
  }
}
.recruitment {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--gap);

  @include for-size(xl) {
    grid-template-columns: 3fr 2fr;
  }

  @include for-size(ls) {
    grid-template-columns: 1fr;
  }

  &__heading {
    color: #fff;
  }

  &__text {
    max-width: 720px;
    p {
      color: #fff;
    }
  }
  &__action {
    margin-top: 70px;
  }
  &__image {
    height: 100%;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
