<template>
  <div class="bg">
    <img :src="publicPath + 'images/bg.jpg'" />
  </div>

  <div class="page">
    <router-view name="mainContent" />
  </div>

  <div class="footer">
    <div class="container">
      <div class="footer__inner">
        <div class="footer__left">
          &copy; {{ year }} FBC Dragons, z.s.
        </div>
        <div class="footer__right">

        </div>
      </div>
    </div>
  </div>

  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;900&display=swap" rel="stylesheet">
</template>

<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL
    };
  },
  components: {
  },
};
</script>

<style lang="scss" scoped>
.bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.page {
  position: relative;
  z-index: 2;
}
.footer {
  background: #000;
  color: #fff;
  font-size: .7rem;
  position: relative;
  z-index: 2;
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;
  }
}
</style>

