<template>
  <div class="hero-wrapper">
    <div class="message">
      <div>
        <strong>Oznámení o konání Valné hromady:</strong> Dne 10.11.2023 od
        20:00 se uskuteční Valná hromada FBC Dragons, z.s. v prostorách
        tělocvičny SSP Kyjov. Všichni jste srdečně zváni. Oficiální pozvánku
        naleznete ve formátu PDF
        <a href="/pozvanka.pdf" target="_blank">zde</a>, program naleznete
        <a href="/program.pdf" target="_blank">zde</a>.
      </div>
    </div>

    <div class="container">
      <div class="hero">
        <div class="hero__logo" data-aos="flip-right" data-aos-duration="1500">
          <img
            :src="publicPath + 'images/logo.svg'"
            alt="FBC Dragons"
            title="FBC Dragons"
          />
        </div>

        <h1
          class="hero__heading"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="700"
        >
          FBC Dragons
        </h1>

        <div
          class="hero__text"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="1000"
        >
          <p>
            V roce 2014 jsme v Kyjově založili florbalový oddíl. Tehdy jsme
            začínali se zhruba 60 členy. Od té doby jsme se rozrostli na téměř
            trojnásobný počet členů a máme družstva od přípravky až po veterány.
            Naše základní hodnota se však nezměnila: Klub stavíme na kvalitní
            práci s mládeží.
          </p>
        </div>

        <div
          class="hero__actions"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="1300"
        >
          <a href="#nabor" class="btn"> Chci být Drak! </a>
        </div>

        <a
          href="#nabor"
          class="scroll"
          data-aos="fade-right"
          data-aos-duration="1000"
          data-aos-delay="1300"
        >
          <span class="scroll__text"> Zjistit více </span>
          <div class="scroll__icon">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import AOS from 'aos';

export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  mounted() {
    AOS.init();
  },
};
</script>

<style lang="scss" scoped>
@keyframes scrollArrow {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.hero-wrapper {
  height: 100vh;
  background: linear-gradient(to top, rgba(#fff, 0.9), rgba(#fff, 0));

  @include for-size(sm) {
    height: calc(100vh - 150px);
  }
}

.message {
  background: var(--primary-color);
  padding: 15px 20px;
  font-size: 12px;
  text-align: center;
  color: #fff;
  a {
    color: #fff;
    text-decoration: underline;
  }
}
.hero {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding-bottom: 150px;

  @media (max-height: 800px) {
    padding-bottom: 70px;
  }

  @include for-size(sm) {
    padding-bottom: 150px;
    justify-content: flex-end;
    height: calc(100vh - 150px);
  }

  &__logo {
    width: 170px;
    margin-bottom: 30px;

    @include for-size(lg) {
      width: 150px;
    }

    @media (max-height: 800px) {
      width: 100px;
    }

    @include for-size(md) {
      width: 120px;
    }
    @include for-size(sm) {
      width: 100px;
      margin-bottom: 15px;
    }
  }
  &__text {
    max-width: 1000px;
    font-size: 1rem;
    margin-bottom: 30px;

    @include for-size(sm) {
      font-size: 0.9rem;
      margin-bottom: 15px;
    }
  }
}

.scroll {
  color: #000;
  font-weight: 400;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  &__text {
    position: relative;
    padding-bottom: 5px;
  }
  &__icon {
    position: relative;
    margin-bottom: 50px;
    span {
      position: absolute;
      top: 0;
      left: 50%;
      width: 15px;
      height: 15px;
      margin-left: -6px;
      border-left: 1px solid currentColor;
      border-bottom: 1px solid currentColor;
      transform: rotate(-45deg);
      animation: scrollArrow 2s infinite;
      opacity: 0;
      box-sizing: border-box;
      &:nth-of-type(1) {
        animation-delay: 0s;
      }
      &:nth-of-type(2) {
        top: 16px;
        animation-delay: 0.15s;
      }
      &:nth-of-type(3) {
        top: 32px;
        animation-delay: 0.3s;
      }
    }
  }
  &:hover {
    text-decoration: none;
  }

  @include for-size(sm) {
    left: 0;
    transform: translateX(0);
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    &__text {
      padding-bottom: 0;
      padding-left: 20px;
      order: 2;
    }
    &__icon {
      order: 1;
      margin: 20px;
      margin-bottom: 0;
      span {
        top: -40px;
        &:nth-of-type(2) {
          top: -24px;
        }
        &:nth-of-type(3) {
          top: -8px;
        }
      }
    }
  }
}
</style>
