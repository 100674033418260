<template>

  <Hero />
  <Recruitment />
  <MembersSection />
  <Contact />

</template>

<script>
import Contact from '../components/Contact.vue';
import Hero from '../components/Hero.vue';
import MembersSection from '../components/MembersSection.vue';
import Recruitment from '../components/Recruitment.vue';
import AOS from "aos";

export default {
  mounted() {
    AOS.init();
  },
  components: {
    Hero,
    Recruitment,
    MembersSection,
    Contact
  }
};
</script>
