<template>
  <div class="contacts-wrapper">
    <div class="container">
      <h2 class="contacts-wrapper__heading" data-aos="fade-up" data-aos-duration="1000">
        Kontakty
      </h2>
      <div class="contacts">
        <div class="contacts__column contacts__column--two-on-phone" data-aos="fade-right" data-aos-duration="1000">
          <h3>FBC Dragons, z.s.</h3>
          <div class="contact contact--mb">
            Sídliště U Vodojemu 1262/20<br>
            697 01 Kyjov
          </div>
          <a href="http://wwwinfo.mfcr.cz/cgi-bin/ares/darv_res.cgi?ico=03123901&jazyk=cz&xml=1" target="_blank" rel="nofollow" class="contact">
            IČO: 03123901
          </a>
          <div class="contact">
            Č. účtu: 266132078/0300
          </div>
          <a href="https://www.ceskyflorbal.cz/oddil/fbc-dragons" target="_blank" rel="nofollow" class="contact">
            Číslo oddílu na ČF: 982
          </a>
        </div>

        <div class="contacts__column" data-aos="fade-right" data-aos-duration="1000" data-aos-delay="300">
          <h3>Filip Šimanovský</h3>
          <div class="contact contact--mb">
            sekretář
          </div>
          <a href="mailto:sekretar@fbc-dragons.cz" class="contact">
            sekretar@fbc-dragons.cz
          </a>
          <a href="tel:00420702052077" class="contact">
            +420 702 052 077
          </a>
        </div>

        <div class="contacts__column" data-aos="fade-right" data-aos-duration="1000" data-aos-delay="600">
          <h3>Pavel Berka</h3>
          <div class="contact contact--mb">
            šeftrenér
          </div>
          <a href="mailto:berka@fbc-dragons.cz" class="contact">
            berka@fbc-dragons.cz
          </a>
          <a href="tel:00420605548669" class="contact">
            +420 605 548 669
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AOS from "aos";

export default {
  data() {
    return {
    };
  },
  mounted() {
    AOS.init();
  }
};
</script>

<style lang="scss" scoped>
.contacts-wrapper {
  background-image: url('~@/assets/panel2.jpg');
  background-size: cover;
  padding: 150px 0;

  @include for-size(md) {
    padding: 100px 0;
  }

  &__heading {
    color: #fff;
  }
}
.contacts {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: var(--gap);
  color: #fff;

  @include for-size(ls) {
    grid-template-columns: 1fr 1fr;
  }

  @include for-size(md) {
    grid-template-columns: 1fr;
  }

  &__column {
    &--two-on-phone {
      @include for-size(ls) {
        grid-column: 1/3;
      }
      @include for-size(md) {
        grid-column: 1;
      }
    }
  }
}
.contact {
  font-size: 1rem;
  color: #fff;
  text-decoration: none;
  display: block;
  &--mb {
    margin-bottom: 20px;
  }
  &:is(a):hover {
    text-decoration: underline;
  }
}
</style>
