import { createApp } from 'vue';
import App from './App.vue';
import router from './router.js';
import '@/scss/customProperties.scss';
import '@/scss/atoms/atoms.scss';
import 'aos/dist/aos.css'

const app = createApp(App);
app.use(router);
app.mount('#app');
